import { AccountByBorrowerResponse } from "../models/response/accountByBorrowerResponse";
import { borrowerRequest } from "../models/request/borrowerRequest";
import { borrowersRequest } from "../models/request/borrowersRequest";
import { borrowerResponse } from "../models/response/borrowerResponse";
import { borrowersResponse } from "../models/response/borrowersResponse";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";
import { AccountByBorrowerRequest } from "../models/request/accountByBorrowerRequest";
import { EditBorrowerRequest } from "../models/request/editBorrowerRequest";
import {decrypt} from "../../utils/decryption";
import { borrower } from "../models/types/borrower";

export const getBorrowers = async ({
  size,
  page,
  lender,
  filter,
  searchString,
}: borrowersRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const borrowersResponse: borrowersResponse = await createRequest(
      reqObj
    ).then((e) => e?.data.body);

    updateGetBorrowersResponse(borrowersResponse); 
    return borrowersResponse;
  } catch (error) {
    console.log(error);
  }
};


const updateGetBorrowersResponse = (borrowersResponse: borrowersResponse) => {
  if (borrowersResponse.total <= 0) {
    return borrowersResponse;
  }
  const updatedBorrowers = borrowersResponse.borrowers.map((borrower : borrower) => {
    return {
      ...borrower,
      name: decrypt(borrower.name),
      email: decrypt(borrower.email),
      phone: decrypt(borrower.phone),
      address: decrypt(borrower.address),
    };
  });
  borrowersResponse.borrowers = updatedBorrowers;
};


export const getBorrower = async ({ lender, borrowerId }: borrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const borrowerResponse: borrowerResponse = await createRequest(reqObj).then(
      (e) => {
        return e?.data.body;
      }
    );
    updateGetBorrowerResponse(borrowerResponse)
    return borrowerResponse;
  } catch (error) {
    console.log(error);
  }
};

const updateGetBorrowerResponse = (borrowerResponse : borrowerResponse) => {
  if (borrowerResponse.borrower){
    borrowerResponse.borrower.dob = decrypt(borrowerResponse.borrower.dob);
    borrowerResponse.borrower.email = decrypt(borrowerResponse.borrower.email);
    borrowerResponse.borrower.phone = decrypt(borrowerResponse.borrower.phone);
    borrowerResponse.borrower.secondaryPhone = decrypt(borrowerResponse.borrower.secondaryPhone);
    borrowerResponse.borrower.country = decrypt(borrowerResponse.borrower.country);
    borrowerResponse.borrower.fullName = decrypt(borrowerResponse.borrower.fullName);
    borrowerResponse.borrower.firstName = decrypt(borrowerResponse.borrower.firstName);
    borrowerResponse.borrower.lastName = decrypt(borrowerResponse.borrower.lastName);
    borrowerResponse.borrower.idNumber = decrypt(borrowerResponse.borrower.idNumber);
    if (borrowerResponse.borrower.address){
      borrowerResponse.borrower.address.addressLine1 = decrypt(borrowerResponse.borrower.address.addressLine1);
      borrowerResponse.borrower.address.city = decrypt(borrowerResponse.borrower.address.city);
      borrowerResponse.borrower.address.country = decrypt(borrowerResponse.borrower.address.country);
      borrowerResponse.borrower.address.zip = decrypt(borrowerResponse.borrower.address.zip);
    }
  }
};

export const getAccountsByBorrower = async ({
  lender,
  borrowerId,
  filter,
  searchString,
  size,
  page,
}: AccountByBorrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/accounts/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const accountByBorrowerResponse: AccountByBorrowerResponse =
      await createRequest({ path, method: "get" }).then((e) => e?.data.body);

    updateGetAccountsByBorrowerResponse(accountByBorrowerResponse);
    return accountByBorrowerResponse;
  } catch (error) {
    console.log(error);
  }
};

const updateGetAccountsByBorrowerResponse = (accountByBorrowerResponse : AccountByBorrowerResponse) => {
  if (accountByBorrowerResponse.total <= 0){
    return accountByBorrowerResponse;
  }
  accountByBorrowerResponse.accounts.forEach((account) => {
    account.referenceID = decrypt(account.referenceID);
    account.dueDate = decrypt(account.dueDate);
    account.loanType = decrypt(account.loanType);
  });
};

export const updateBorrower = async ({
  lender,
  borrowerId,
  body,
}: EditBorrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}`;
    const res: any = await createRequest({ path, method: "put", data: body });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async ({
  lender,
  borrowerId,
  userId,
  body,
}: EditBorrowerRequest) => {
  try {
    const path: string = `/lenders/${lender}/borrowers/${borrowerId}/user/${userId}`;
    const res: any = await createRequest({ path, method: "put", data: body });
    return res;
  } catch (error) {
    console.log(error);
  }
};
