import {
  disputeRequest,
  disputeTypesRequest,
  disputeTypesUpdateRequest,
  updateDisputeRequest,
} from "../models/request/disputeRequest";
import { disputesRequest } from "../models/request/disputesRequest";
import { ICreateRequest } from "../models/types/axiosCreateRequest";
import { createRequest } from "./axios";
import {decrypt} from "../../utils/decryption";


export const getDispiutes = async ({
  size,
  page,
  lender,
  filter,
  searchString,
}: disputesRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/all?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    updateGetDispiutes(res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

const updateGetDispiutes = (disputesResponse: any) => {
  if (disputesResponse.total <= 0) {
    return;
  }
  disputesResponse.disputes.map((dispute: any) => {
    dispute.name = decrypt(dispute.name );
    dispute.reason = decrypt(dispute.reason);
    dispute.fileUrl = decrypt(dispute.fileUrl);
    return dispute;
  });
}

export const getDispiutesByAccountId = async ({
  size,
  page,
  lender,
  filter,
  accountId,
  searchString,
}: disputesRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/account/${accountId}?${
      filter !== "" ? `filter=` + filter + `&` : ``
    }page=${page}&size=${size}&searchString=${searchString}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    updateGetDisputesByAccountId(res)
    return res;
  } catch (error) {
    console.log(error);
  }
};

const updateGetDisputesByAccountId = (disputesResponse: any) => {
  if (disputesResponse.total <= 0){
    return;
  }
  disputesResponse.disputes.forEach((dispute: any) => {
    dispute.name = decrypt(dispute.name);
    dispute.reason = decrypt(dispute.reason);
    dispute.fileUrl = decrypt(dispute.fileUrl);
  });
}

export const getDispiute = async ({ lender, id }: disputeRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/${id}`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    updateGetDispute(res)
    return res;
  } catch (error) {
    console.log(error);
  }
};

const updateGetDispute = (disputeResponse: any) => {
  if (disputeResponse.dispute){
    disputeResponse.dispute.name = decrypt(disputeResponse.dispute.name);
    disputeResponse.dispute.reason = decrypt(disputeResponse.dispute.reason);
    disputeResponse.dispute.fileUrl = decrypt(disputeResponse.dispute.fileUrl);
    disputeResponse.dispute.note = decrypt(disputeResponse.dispute.note);
    if (disputeResponse.dispute.answers) {
      disputeResponse.dispute.answers.forEach((answer: any) => {
        answer.comment = decrypt(answer.comment);
      });
    }
  }
}

export const updateDispiute = async ({
  lender,
  id,
  body,
}: updateDisputeRequest) => {
  try {
    const path: string = `/lenders/${lender}/disputes/${id}`;

    const reqObj: ICreateRequest = {
      path,
      method: "put",
      data: body,
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getDispiuteTypes = async ({ lender }: disputeTypesRequest) => {
  try {
    const path: string = `/lenders/${lender}/dispute-types/all`;

    const reqObj: ICreateRequest = {
      path,
      method: "get",
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateDispiuteTypes = async ({
  lender,
  body,
}: disputeTypesUpdateRequest) => {
  try {
    const path: string = `/lenders/${lender}/dispute-types/all`;

    const reqObj: ICreateRequest = {
      path,
      method: "post",
      data: body,
    };

    const res: any = await createRequest(reqObj).then((e) => e?.data.body);
    return res;
  } catch (error) {
    console.log(error);
  }
};
