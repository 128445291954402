import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_API_RESPONSE_DECRYPTION_SECRET_KEY as string;

export function decrypt(encryptedData : any) {
    if (!encryptedData || encryptedData.trim() === '') {
        return encryptedData;
    }
    const base64Decoded = CryptoJS.enc.Base64.parse(encryptedData);
    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: base64Decoded } as any,
        CryptoJS.enc.Utf8.parse(secretKey),
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
}